@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  
  
  @font-face {
    font-family: 'Merriweather Regular';
    src: local('Merriweather-Regular'), url('./fonts/Merriweather-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Merriweather Light';
    src: local('Merriweather-Light'), url('./fonts/Merriweather-Light.ttf') format('truetype');
  }
 
  @font-face {
    font-family: 'Merriweather Bold';
    src: local('Merriweather-Bold'), url('./fonts/Merriweather-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Merriweather Black';
    src: local('Merriweather-Black'), url('./fonts/Merriweather-Black.ttf') format('truetype');
  }
  
  
  @font-face {
    font-family: 'Merriweather Italic';
    src: local('Merriweather-Italic'), url('./fonts/Merriweather-Italic.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Merriweather LightItalic';
    src: local('Merriweather-LightItalic'), url('./fonts/Merriweather-LightItalic.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Merriweather BoldItalic';
    src: local('Merriweather-BoldItalic'), url('./fonts/Merriweather-BoldItalic.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Merriweather BlackItalic';
    src: local('Merriweather-BlackItalic'), url('./fonts/Merriweather-BlackItalic.ttf') format('truetype');
  }
  
  
  html {
    font-family: "Merriweather Light"; 
    background-color: rgb(219, 234, 254);
    color: rgb(10, 10, 10);
  }

  .bold {
    font-family: "Merriweather Bold";
  }
  .semi-bold
  {
    font-family: "Merriweather Regular";
  }

  .dark {
    
    background-color: rgb(40, 40, 40);
    color: rgb(246, 246, 246);
  }
}
.flippable-card-container-dimensions{
  margin: auto;
  height: 500px;
  width: 300px;
}

.flippable-card-container {
  perspective: 1000px;
}
.card {
  cursor: pointer;
  height:100%;
  width: 100%;

  transform-style: preserve-3d;

  position: relative;
  border-radius: 5px;
  border: 5px solid black;
  box-shadow: 0px 0px 15px rgba(230, 230, 230, 0.8);
}

.card-placeholder {
  cursor: pointer;
  height:100%;
  width: 100%;

  transform-style: preserve-3d;

  position: absolute;
 
}

.backface-hidden{
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-front,.card-back{
  height:100%;
  width: 100%;

  position:absolute;
  

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.card-front{
  background-color: transparent;
}

.card-back {
  transform: rotateY(180deg);
}

.flip-exit {
  transform: rotateY(0deg);
}

.flip-exit-active {
  transform: rotateY(180deg);
  transition: transform 750ms;
  transition-timing-function: cubic-bezier(0.57,0.21,0.69,1.25);
}

.flip-exit-done {
  transform: rotateY(180deg);
}
.flip-enter {
  transform: rotateY(180deg);
}

.flip-enter-active {
  transform: rotateY(0deg);
  transition: transform 750ms;
  transition-timing-function: cubic-bezier(0.57,0.21,0.69,1.25);
}

.flip-enter-done {
  transform: rotateY(0deg);
}

.portrait {
  height: 32%;
  width: 24%;
}

.page{
  position:relative
}

.page-enter {
  opacity: 0;
  
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}


.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

